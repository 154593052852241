import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerAccess() {
    var url = ConfigAPI.baseURL + "access" + Session.getToken();
    return instance.get(url);
  },
  mostrarAccess(id) {
    var url = ConfigAPI.baseURL + "access/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarAccess(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "access" + Session.getToken();
    return instance.post(url,params);
  },
  editarAccess(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "access/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarAccess(id) {
    var url = ConfigAPI.baseURL + "access/"+ id + Session.getToken();
    return instance.delete(url);
  },   
    
}

export default servicesAPI;
